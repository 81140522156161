import React from 'react';
import {Link} from 'react-router-dom';
import {
  InfoMedia, 
  Widget, 
  AboutWidget, 
  CategoryWidget, 
  RecentPostWidget, 
  IconLink
} from '../../components/';

// Widget Datas
import widgetsdata from '../../data/widgets.json';

const {pageList, exploreLinks, recentPosts} = widgetsdata;


const FooterThree = ()=> (
  <footer className="footer-wrapper footer-layout3" >
    <div className="footer-top">
      <div className="container">        
        <div className="row">        
          <div className="footer-media style2 col-sm ">
            <InfoMedia
              icon="fal fa-map-marker-alt"
              title="Office Address"
              info="24z, Tharwat Abu El Goukh Street, Maadi, Cairo, Egypt"
            />
          </div>
          <div className="footer-media style2 col-sm">
            <InfoMedia
              icon="fal fa-clock"
              title="Working Hours"
              info="Sunday - Thursday 8:00AM - 7:00PM"
            />
          </div>
          <div className="footer-media style2 col-sm">
            <InfoMedia
              icon="fal fa-phone-volume"
              title="Contact Us"
              info={<>
                <a href="mailto:contact@iken.tech">contact@iken.tech</a>
                <br/>
                <a href="tel:+201001550925">(+20) 100 155 0925</a>
              </>}
            />
          </div>
        </div>
      </div>
    </div>
    <div className="widget-area">
      <div className="container">
        <div className="row justify-content-between">            
          <div className="col-md-6 col-xl-auto">
            <Widget widgetTitle="About Us" className="footer-widget">
              <AboutWidget>
                <AboutWidget.Text>We help businesses grow and succeed by providing expert consulting services.</AboutWidget.Text>
                <IconLink className="footer-social">
                  <IconLink.Item icon="fab fa-facebook-f" path="https://www.facebook.com/IKEN.tech"/>
                  <IconLink.Item icon="fab fa-twitter" path="https://twitter.com/iken_tech"/>
                  <IconLink.Item icon="fab fa-linkedin" path="https://eg.linkedin.com/company/iken-tech"/>
                  <IconLink.Item icon="fab fa-instagram" path="https://www.instagram.com/iken.tech"/>
                </IconLink>
              </AboutWidget>
            </Widget>
          </div>
          <div className="col-6 col-xl-auto">            
            <Widget widgetTitle="Links" className="widget_nav_menu footer-widget">
              <CategoryWidget>
                {pageList.map(cat => (
                  <CategoryWidget.Item key={cat.name} path={cat.path}>{cat.name}</CategoryWidget.Item>
                ))}
              </CategoryWidget>
            </Widget>
          </div>
          <div className="col-6 col-xl-auto">            
            <Widget widgetTitle="Explore" className="widget_nav_menu footer-widget">
              <CategoryWidget>
                {exploreLinks.map(cat => (
                  <CategoryWidget.Item key={cat.name} path={cat.path}>{cat.name}</CategoryWidget.Item>
                ))}
              </CategoryWidget>
            </Widget>
          </div>
          {/* <div className="col-md-6 col-xl-auto">            
            <Widget widgetTitle="Lastest Post" className="footer-widget">
              {recentPosts.slice(0, 2).map(post => (
                <RecentPostWidget key={post.id}
                  title={post.title}
                  image={post.image}
                  date={post.date}
                />
              ))}
            </Widget>
          </div> */}
        </div>
      </div>
    </div>
    <div className="copyright-wrap">
      <div className="container">
        <p className="copyright-text">Copyright <i className="fal fa-copyright"/> 2023 <Link className="text-white" to="/">IKEN Technology</Link>. All rights reserved.</p>
      </div>
    </div>    
  </footer>
);

export default FooterThree;